.snack_root {
  margin-bottom: 20px;
  &.error_snack {
    div {
      background-color: #FF4D62;
      font-family: "Inter", sans-serif;
      max-width: none;
      svg {
        fill: #ffffff;
      }
    }
  }
  &.game_center_error_snack {
    margin-left: 120px;
    div {
      background-color: #FF4D62;
      font-family: "Inter", sans-serif;
      svg {
        fill: #ffffff;
      }
    }
  }
  &.success_snack {
    div {
      background-color: #28A745;
      font-family: "Inter", sans-serif;
      svg {
        fill: #ffffff;
      }
    }
  }
  &.game_over_info_snack {
    & > div {
      text-align: center;
      flex-wrap: nowrap;
      background-color: #0E1832;
      font-family: "Inter", sans-serif;
      border: 1px solid #373947;
      color: #737A93;
      letter-spacing: -0.266667px;
      font-size: 12px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
      max-width: none;
      svg {
        fill: #ffffff;
      }
    }
  }
  &.panel_margin {
   margin-left: 120px;
  }
}