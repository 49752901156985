.auth_button_wrapper {
  .auth_button {
    text-align: center;
    display: inline-flex;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 50px;
    height: 50px;
    min-width: 150px;
    padding: 0 20px;
    border-radius: 26.78px;
    box-shadow: none;
    white-space: nowrap;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:disabled {
      opacity: 0.6;
      color: #ffffff;
    }
    &.auth_button_contained {
      background-image: linear-gradient(to right, #D2273C, #FF344D, #D2273C, #FF344D);
      border: none;
      background-size: 300% 100%;
      moz-transition: all .4s ease-in-out;
      -o-transition: all .4s ease-in-out;
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
      div {
        color: #ffffff;
      }
      &:hover {
        background-position: 100% 0;
      }
    }
    &.auth_button_green {
      background: #2FC272;
      border: none;
      background-size: 300% 100%;
      moz-transition: all .4s ease-in-out;
      -o-transition: all .4s ease-in-out;
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
      div {
        color: #ffffff;
      }
      &:hover {
        background-position: 100% 0;
      }
    }
    &.auth_button_outlined {
      color: rgba(255,255,255,1);
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 26.78px;
        border: 1px solid rgba(255,255,255,1);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
      &:hover:before{
        opacity: 0;
        -webkit-transform: scale(1.2,1.2);
        transform: scale(1.2,1.2);
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        border-radius: 26.78px;
        background-color: rgba(255,255,255,0.25);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-transform: scale(0.5,0.5);
        transform: scale(0.5,0.5);
      }
      &:hover:after{
        opacity: 1 ;
        -webkit-transform: scale(1,1);
        transform: scale(1,1);
      }
    }
  }
}

.auth_button_profile {
  border-radius: 74px!important;
  width: 189px;
  height: 40px!important;
  font-weight: 500!important;
  font-size: 14px!important;
  text-transform: none!important;
  line-height: 40px !important;
}

.auth_button_profile_chat {
  border-radius: 74px!important;
  width: 120px;
  height: 40px!important;
  font-weight: 500!important;
  font-size: 14px!important;
  text-transform: none!important;
  margin-left: 13px !important;
  line-height: 40px !important;
}

.smaller {
  width: 204px;
}

.auth_button_toast {
  border-radius: 27px!important;
  width: 90px!important;
  min-width: unset!important;
  height: 34px!important;
  font-weight: 500!important;
  font-size: 14px!important;
  text-transform: none!important;
  line-height: 34px !important;
}