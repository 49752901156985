.default_dialog_root {
  & > :first-child {
    background-color: rgba(5, 9, 18, 0.8);
  }
  & > :nth-child(3) {
    & > :first-child {
      background: transparent;
      box-shadow: 0 10px 40px 0 rgba(0,0,0,0.25);
      border-radius: 13px;
      max-width: 698px!important;
    }
  }
  .dialog_close_button {
    cursor: pointer;
    position: absolute;
    right: 26.5px;
    top: 31.5px;
    padding: 9.5px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid rgba(126,141,156,0.6);
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.dialog_wrapper {
  background: #0E1832;
  border: 1px solid #373947;
  box-sizing: border-box;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.how_to_submit_results {
  padding: 20px 20px 40px;
  img {
    margin-left: auto;
    cursor: pointer;
  }
  h2 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.5824px;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
}



