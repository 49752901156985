@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,900');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400');

@import "./reset";
@import "./base";

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  src: url("../assets/fonts/Inter-Regular.woff2") format("woff2"),
  url("../assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  src: url("../assets/fonts/Inter-Italic.woff2") format("woff2"),
  url("../assets/fonts/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  src: url("../assets/fonts/Inter-Medium.woff2") format("woff2"),
  url("../assets/fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  src: url("../assets/fonts/Inter-MediumItalic.woff2") format("woff2"),
  url("../assets/fonts/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: url("../assets/fonts/Inter-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  src: url("../assets/fonts/Inter-SemiBoldItalic.woff2") format("woff2"),
  url("../assets/fonts/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  src: url("../assets/fonts/Inter-Bold.woff2") format("woff2"),
  url("../assets/fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  src: url("../assets/fonts/Inter-BoldItalic.woff2") format("woff2"),
  url("../assets/fonts/Inter-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  src: url("../assets/fonts/Inter-ExtraBold.woff2") format("woff2"),
  url("../assets/fonts/Inter-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  src: url("../assets/fonts/Inter-ExtraBoldItalic.woff2") format("woff2"),
  url("../assets/fonts/Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  src: url("../assets/fonts/Inter-Black.woff2") format("woff2"),
  url("../assets/fonts/Inter-Black.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  src: url("../assets/fonts/Inter-BlackItalic.woff2") format("woff2"),
  url("../assets/fonts/Inter-BlackItalic.woff") format("woff");
}