* {
  box-sizing: border-box;
}

body {
  background: linear-gradient(180deg, #080B1B 0%, #0D172A 100%);
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  color: #ffffff;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #192036;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #6F7481;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #6F7481;
  }
}

.page_wrapper{
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  width: 100%;
  padding: 80px 40px;
}

a, button{
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.text_hover {
  cursor: pointer;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  &:hover{
    opacity: 0.5;
  }
}

.card_hover {
  cursor: pointer;
  -webkit-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -ms-transition: all ease .2s;
  -o-transition: all ease .2s;
  transition: all ease .2s;
  &:hover{
    margin-top: -3px;
    margin-bottom: 3px;
  }
}

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
