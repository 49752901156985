.blogger_header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #0E1832;
  padding: 0 40px;
  z-index: 99;
}

.header-logo {
  & > :first-child {
    margin-right: 20px;
  }
}

.header_options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60%;
  color: #ffffff;
  svg {
    fill: #ffffff;
  }
  & > button {
    max-width: 50%;
  }
  button {
    line-height: 2!important;
    padding: 0;
    display: flex;
    min-width: unset!important;
    p {
      color: #ffffff;
      font-family: "Inter", sans-serif;
      font-size: 13px;
      margin: 0 10px;
      text-transform: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .app-menu_button {
    background: #232841;
    border-radius: 16px;
    svg {
      font-size: 14px;
      margin-right: 5px;
    }
    &.menu_open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.head_btn_wrapper {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
}

.head_btn_indicator {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #F85252 0%, #F04242 100%);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
  transform: translateY(-100%);
  &:before {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    left: calc(50% - 5px);
    top: -2px;
    -webkit-clip-path: polygon(50% 0, 0 33%, 100% 33%);
    clip-path: polygon(50% 0, 0 33%, 100% 33%);
    transform: rotate(180deg);
    background-color: #F85252;
  }
  &.indicator_active {
    opacity: 1;
    transform: translateY(0);
  }
}

div {
  .app_head_popover {
    overflow: inherit;
    background: transparent;
    margin-top: 20px;
  }
}

.app-menu {
  width: 138px;
  background-color: #292F4C;
  border: 1px solid #373947;
  padding: 8px 0;
  position: relative;
  border-radius: 6px;
  z-index: 999;
  &:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 25px;
    top: -8px;
    border-left: 1px solid #373947;
    border-top: 1px solid #373947;
    background-color: #292F4C;
    -webkit-transform: rotate(45deg) skew(-20deg, -20deg);
    -moz-transform: rotate(45deg) skew(-20deg, -20deg);
    -ms-transform: rotate(45deg) skew(-20deg, -20deg);
    -o-transform: rotate(45deg) skew(-20deg, -20deg);
    transform: rotate(45deg) skew(-20deg, -20deg);
    z-index: 9999;
  }
  a, button {
    padding: 8px 16px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.logout_wrapper {
  text-align: center;
  h3 {
    font-family: Montserrat, sans-serif;
    font-size: 38px;
    font-weight: 600;
    line-height: 47px;
    color: white;
  }
  img {
    margin: 32px 0;
    max-height: 100px;
  }
  p {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
    color: white;
  }
}

.logout_buttons {
  margin-bottom: 36px;
  .default_button_wrapper {
    display: inline-block;
  }
  & > :first-child {
    margin-right: 13px;
  }
}