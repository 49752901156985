.message_popper {
  .message_tooltip {
    background-color: #29323C;
    font-size: 10px;
    word-break: break-word;
    font-family: Montserrat, sans-serif;
  }
  .error_message_tooltip {
    background-color: #FF4D62;
    font-size: 10px;
    font-family: Montserrat, sans-serif;
  }
}

body {
  .auth_tooltip_popper {
    z-index: 1000000;
    top: 8px;
    left: 9px;
  }
}