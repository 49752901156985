.auth_container {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-evenly;
  background-color: #1B2664;
  height: 100vh;
}

.auth_section {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.auth_section_block {
  background: #051745;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  padding: 80px 64px;
  width: 528px;
  form {
    .custom_input_wrapper {
      margin-bottom: 20px;
    }
  }
}

.auth_title {
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 15px;
}

.auth_subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #A0A6D0;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 39px;
}

.auth_buttons_wrapper {
  margin-top: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}