.default_button_wrapper {
  .default_button {
    border-radius: 26.78px;
    color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-transform: none;
    padding: 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &.default_button_green {
      background: linear-gradient(270deg, #2FC272 0%, #33BC71 100%);
      &:disabled {
        color: white;
        opacity: 0.5;
      }
    }
    &.default_button_white {
      background: #0E1832;
      border: 1px solid #fff;
      color: #FFFFFF;
    }
    &.default_button_red {
      background: #F32A2A;
      &:disabled {
        color: white;
        opacity: 0.5;
      }
    }
    &.default_button_transparent {
      box-shadow: 0 10px 40px 0 rgba(0,0,0,0.25);
      background: transparent;
      border: 1px solid #fff;
    }
    &.default_button_big {
      height: 50px;
      width: 216px;
      line-height: 50px;
    }
    &.default_button_large {
      height: 45px;
      width: 300px;
      line-height: 45px;
    }
    &.default_button_medium {
      height: 50px;
      width: 175px;
      line-height: 50px;
    }
    &.default_button_mediocre {
      height: 50px;
      width: 146px;
      line-height: 50px;
    }
    &.default_button_sf_small {
      height: 40px;
      width: 146px;
      line-height: 40px;
      margin-bottom: 4px;
    }
    &.default_button_smallest {
      height: 26px;
      width: 90px;
      line-height: 26px;
    }
    &.default_button_small {
      height: 41px;
      width: 92px;
      line-height: 41px;
    }
    &.default_button_xbox {
      background: #1A6444;
      border-radius: 4px;
      height: 40px;
      width: 180px;
      line-height: 40px;
    }
    &.default_button_psn {
      background: #3A67D0;
      border-radius: 4px;
      height: 40px;
      width: 180px;
      line-height: 40px;
    }
    &.default_button_steam {
      background: #88B940;
      border-radius: 4px;
      height: 40px;
      width: 180px;
      line-height: 40px;
      font-size: 12px;
      img {
        margin-right: 7px;
      }
    }
    &.default_button_quit {
      background: transparent;
      border: 1px solid #F75151;
      border-radius: 26.7836px;
      color: #F32A2A;
      font-weight: 600;
      font-size: 14px;
    }
    &.default_button_submit_results {
      box-shadow: 0 7px 12px rgba(47, 194, 114, 0.34);
      border-radius: 31px;
      font-weight: 600;
      font-size: 14px;
      height: 50px;
      width: 246px;
    }
    .btn_progress {
      color: #ffffff;
    }
    &.default_button_last_played_game_rematch {
      border-radius: 54px;
      width: 190px;
      height: 40px;
    }
    &.default_button_toast {
      border-radius: 27px;
      width: 160px;
      height: 34px;
      margin-left: 10px;
    }
    &.default_button_connect_profile {
      width: 180px;
      height: 40px;
      background: #88B940;
      border-radius: 4px;
      line-height: unset;
      font-weight: 500;
      font-size: 12px;
    }
    &.default_button_blogger_size {
      width: 100%;
      height: 44px;
      background: #2FC272;
      border-radius: 46px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    &.default_button_paysafecard {
      width: 218px;
      height: 45px;
      background: #2FC272;
      border-radius: 22.5px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}