.dashboard_wrapper {
  width: 100%;
  background: #192036;
  border-radius: 9px;
  padding: 40px;
  .tabs_wrapper {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: #373947;
      bottom: 0;
    }
    .tab_wrapper {
      min-width: unset;
      width: max-content;
    }
  }
  .periods_wrapper {
    display: flex;
    margin: 40px 14px;
    .period {
      margin-right: 32px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.25px;
      color: #FFFFFF;
      opacity: 0.5;
      -webkit-transition: all ease .3s;
      -moz-transition: all ease .3s;
      -ms-transition: all ease .3s;
      -o-transition: all ease .3s;
      transition: all ease .3s;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
      }
    }
  }
  .chart_wrapper {
    margin: 24px 14px 10px;
  }
}